var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-dialog',{attrs:{"persistent":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"dialog-title",attrs:{"color":"primary","dark":""}},on),[_vm._v("Add New User")])]}}]),model:{value:(_vm.createDialog),callback:function ($$v) {_vm.createDialog=$$v},expression:"createDialog"}},[_c('users-create',{on:{"save":_vm.userSaved,"close":function($event){_vm.createDialog = false}}})],1),_c('v-dialog',{attrs:{"persistent":"","fullscreen":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"dialog-title",attrs:{"color":"primary","dark":""}},on),[_vm._v("Edit User")])]}}]),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('users-edit',{attrs:{"selectedUser":_vm.editUser},on:{"save":_vm.userSaved,"close":function($event){_vm.editDialog = false}}})],1),_c('users-newpassword',{attrs:{"user":_vm.selectedUser},on:{"close":function($event){_vm.selectedUser = null},"save":function($event){_vm.selectedUser = null}}})],1),_c('v-row',[_c('v-checkbox',{attrs:{"label":"Show disabled users"},on:{"change":_vm.resetSort},model:{value:(_vm.showDisabled),callback:function ($$v) {_vm.showDisabled=$$v},expression:"showDisabled"}})],1),_c('v-data-table',{attrs:{"items":_vm.showDisabled ? _vm.users : _vm.getActiveUsers(),"headers":_vm.headers,"loading":!_vm.loaded,"sort-by":_vm.sortBy,"custom-sort":_vm.sort},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event}},scopedSlots:_vm._u([{key:"item.training",fn:function(ref){
var item = ref.item;
return [(item.training)?_c('v-chip',{attrs:{"color":"green","label":""}},[_vm._v("Training")]):_vm._e()]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.getRole(item)))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-2 my-2",on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Edit")]),_c('v-btn',{staticClass:"mr-2 my-2",on:{"click":function($event){return _vm.changePassword(item)}}},[_vm._v("Password")]),_c('v-btn',{staticClass:"my-2",on:{"click":function($event){return _vm.viewDocument(item.id, item.fullName)}}},[_vm._v("Documents")])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }