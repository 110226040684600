<template>
    <div class="form_wrapper">
        <form>
            <v-text-field
                autocorrect="off"
                v-model="name"
                :error-messages="nameErrors"
                label="Full Name"
                required
                @input="$v.name.$touch()"
                @blur="$v.name.$touch()"
            ></v-text-field>
            <v-text-field
                autocorrect="off"
                v-model="email"
                :error-messages="emailErrors"
                label="E-mail"
                required
                @input="$v.email.$touch()"
                @blur="$v.email.$touch()"
            ></v-text-field>
            <v-text-field
                autocorrect="off"
                v-model="password"
                :error-messages="passwordErrors"
                label="Password"
                required
                @input="$v.password.$touch()"
                @blur="$v.password.$touch()"
                type="password"
            ></v-text-field>
            <v-select
                v-model="select"
                :items="items"
                :error-messages="selectErrors"
                label="Item"
                required
                @change="$v.select.$touch()"
                @blur="$v.select.$touch()"
            ></v-select>

            <v-btn class="mr-4" @click="submit" :to="{ name: 'users' }">submit</v-btn>
            <v-btn @click="clear">clear</v-btn>
        </form>
    </div>
</template>
<style>
.form_wrapper {
    max-width: 600px;
    padding: 2rem;
}
</style>
<script>
import { validationMixin } from "vuelidate";
import { required, minLength, email, password } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],

    validations: {
        name: { required },
        email: { required, email },
        select: { required },
        password: { required, minLength: minLength(8) },
    },
    data: () => ({
        name: "",
        email: "",
        password: "",
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    }),

    computed: {
        selectErrors() {
            const errors = [];
            if (!this.$v.select.$dirty) return errors;
            !this.$v.select.required && errors.push("Item is required");
            return errors;
        },
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.required && errors.push("Name is required.");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push("Must be valid e-mail");
            !this.$v.email.required && errors.push("E-mail is required");
            return errors;
        },
        passwordErrors() {
            const errors = [];
            const field = this.$v.password.password;

            if (!field.$dirty) return errors;

            if (!field.required) {
                errors.push("New password is required");
            }
            if (!field.minLength) {
                errors.push("Password must be 8 or more characters long");
            }
            if (!/[A-Z]/.test(field.$model)) {
                errors.push("Password must contain at least one uppercase letter");
            }
            if (!/[a-z]/.test(field.$model)) {
                errors.push("Password must contain at least one lowercase letter");
            }
            if (!/\d/.test(field.$model)) {
                errors.push("Password must contain at least one digit");
            }
            if (!/\W/.test(field.$model)) {
                errors.push("Password must contain at least one special character");
            }

            return errors;
        },
    },

    methods: {
        submit() {
            this.$v.$touch();
        },
        clear() {
            this.$v.$reset();
            this.name = "";
            this.email = "";
            this.password = "";
            this.select = null;
        },
    },
};
</script>
